import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const NavBar = () => {
    const [active, setActive] = useState("nav__menu");
    const navToggle = () => {
        active === 'nav__menu' ?
            setActive('nav__menu nav__active') :
            setActive('nav__menu');
    };

    return (
        <nav className='nav navbar-fixed-top' >
            <Link className="navbar-brand">
                <Link to="/" className='trustName'>Rainbow Trust</Link>
            </Link>
            <ul onClick={navToggle} className={active}>
                <li className="nav__item">
                    <Link to="/" className="nav__link" id={window.location.pathname === "/" ? "active" : ""}>
                        Home
                    </Link>
                </li>
                <li className="nav__item">
                    <Link to="/Gallery" className="nav__link" id={window.location.pathname === "/Gallery" ? "active" : ""}>
                        Gallery
                    </Link></li>
                <li className="nav__item">
                    <Link to="/Donation" className="nav__link" id={window.location.pathname === "/Donation" ? "active" : ""}>
                        Donation
                    </Link></li>
                <li className="nav__item">
                    <Link to="/About" className="nav__link" id={window.location.pathname === "/About" ? "active" : ""}>
                        About
                    </Link></li>
                <li className="nav__item">
                    <Link to="/Contact" className="nav__link" id={window.location.pathname === "/Contact" ? "active" : ""}>
                        Contact
                    </Link></li>
            </ul>
            <div onClick={navToggle} className="nav__toggle">
                <div className="line1"></div>
                <div className="line2"></div>
                <div className="line3"></div>
            </div>
        </nav>
    )
}

export default NavBar;                          

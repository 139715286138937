import React from 'react'
import { Link } from 'react-router-dom';
const Success = () => {
  return (
    <>
      <div style={{ padding: "10px" }}>
        <div className="entry-header" >
          <div className='nv-title-meta-wrap' style={{ padding: "10px" }}>
            <div className='' style={{ padding: "10px" }}>
              <h1 className='entry-title'>Payment Success</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/" className="text-white">Home</Link></li>
                  <li className="breadcrumb-item active text-white" aria-current="page">Successfull </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div className="S_container">
        <div className="row justify-content-center">
          <div className="col-md-6 text-center">
            <div className="alert alert-success text-center">
              <h4 className="alert-heading">Payment Successfull</h4>
            </div>
            <a href='/'>Back to Home</a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Success

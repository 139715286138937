import React from 'react'
import Footer from './Footer'
import { Link } from 'react-router-dom'
const ReturnPolicy = () => {
    return (
        <>
            <div style={{ padding: "10px" }}>
                <div className="entry-header" >
                    <div className='nv-title-meta-wrap' style={{ padding: "10px" }}>
                        <div className='' style={{ padding: "10px" }}>
                            <h1 className='entry-title'>Return Policy</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/" className="text-white">Home</Link></li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <div className='RP'>
                <p><h2 className='RP-header'><b>DISCLAIMER</b></h2></p>
                <p>Last Updated: 2024-04-08</p>
                <p><b>REFUNDS</b></p>
                <p className='RP-content'>All funds and donations are final, and no refund will be issued.</p>
                <p><b>QUESTIONS</b></p>
                <p className='RP-content'>If you have any questions concerning our return policy, please contact us at:</p>
                <p className='RP-content'>9940350960</p>
                <p className='RP-content'>rainbowtrust3021@gmail.com</p>
            </div>
            <Footer />
        </>
    )
}

export default ReturnPolicy

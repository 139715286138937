import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Footer from '../Footer';

const Phonepe = () => {
    const [loading2, setLoading2] = useState(false);
    const [name, setName] = useState("");
    const [number, setNumber] = useState("");
    const [amount, setAmount] = useState("");

    const data = {
        name: name,
        amount: amount,
        number: number,
        MUID: "MUID" + Date.now(),
        transactionId: 'T' + Date.now(),
    }

    const handlePayment = (e) => {
        e.preventDefault();
        setLoading2(true);
        axios.post('https://rain-bow-trust-website-server.vercel.app/api/payment', { ...data })
            .then(response => {
                console.log(response.data)
                window.location.href = response.data;
            })
            .catch(error => {
                console.error(error);
            });
    }
    return (
        <>
            <div style={{ padding: "10px" }}>
                <div className="entry-header" >
                    <div className='nv-title-meta-wrap' style={{ padding: "10px" }}>
                        <div className='' style={{ padding: "10px" }}>
                            <h1 className='entry-title'>Donation</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/" className="text-white">Home</Link></li>
                                    <li className="breadcrumb-item active text-white" aria-current="page"> Donation</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <div className='DonationForm'>
                <div className="DFormContainer">
                    <form action="" onSubmit={handlePayment} >
                        <div className="DFormRow">
                            <div className="DFormCol">
                                <h3 className="DFormTitle">Payment Details</h3>
                                <div className="DFormInputBox">
                                    <span>full name :</span>
                                    <input type="text" required placeholder='eg: Joshwa' value={name} onChange={(e) => setName(e.target.value)} />
                                </div>
                                <div className="DFormInputBox">
                                    <span>Ph.number :</span>
                                    <input type="tel" pattern='[0-9]{10}' required placeholder='eg: 1234567890' value={number} onChange={(e) => setNumber(e.target.value)} />
                                </div>
                                <div className="DFormInputBox">
                                    <span>Amount :</span>
                                    <input type="number" required placeholder='eg: Rs.10000' value={amount} onChange={(e) => setAmount(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        {/* <input type="submit" value="proceed to pay" className='DFormSubmit-btn' /> */}
                        {!loading2 ? <div className='col-12 center'>
                            <button className='DFormSubmit-btn w-100 ' type="submit">Pay Now</button>
                        </div>
                            :
                            <div className='col-12 center'>
                                <button className='DFormSubmit-btn w-100 text-center' type="submit">
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden ">Wait...</span>
                                    </div>
                                </button>
                            </div>
                        }
                    </form>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Phonepe

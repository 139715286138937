import React from 'react'
import { Link } from 'react-router-dom'
import Footer from './Footer';
import { IoLocation, IoMail } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
const Contact = () => {
  const form = useRef()
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_b08qiu5', 'template_05nm8nn', form.current, 'S-cTIJE2xBMY6DfAq')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    e.target.reset()
  };
  return (
    <div className='contact-page'>
      <div style={{ padding: "10px" }}>
        <div className="entry-header" >
          <div className='nv-title-meta-wrap' style={{ padding: "10px" }}>
            <div className='' style={{ padding: "10px" }}>
              <h1 className='entry-title'>Contact</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/" className="text-white">Home</Link></li>
                  <li className="breadcrumb-item text-white" aria-current="page"> Contact</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div className='heading'>
        <h1>Contact Us</h1>
        <p className='contact-us-top'>Don't hesitate to reach out to us. We are always happy to assist you</p>
      </div>

      <div className='google-map'>
        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1081.8130468880602!2d80.0333725244963!3d13.113366842532841!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52891edb15c631%3A0x5cd0ad863e835e8d!2s6%2014th%2C%205%2C%204th%20St%2C%20Saraswathi%20Nagar%2C%20Tamil%20Nadu%20602024!5e0!3m2!1sen!2sin!4v1709569019296!5m2!1sen!2sin"
          allowfullscreen
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade">
        </iframe> */}
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d5762.788845088223!2d80.05217826429212!3d13.12751817923538!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTPCsDA3JzUyLjYiTiA4MMKwMDMnMDcuOSJF!5e0!3m2!1sen!2sin!4v1718033781046!5m2!1sen!2sin"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="RainBow Trust Location"
        ></iframe>
      </div>

      <section className='contact'>
        <div className="content">
          <p>If you have any questions, feel free to contact us. We'll respond to you as quickly as we can.</p>
        </div>
        <div className="contact-container">
          <div className="contactInfo">
            <div className="box">
              <div className="icon"><i><IoLocation /></i></div>
              <div className="text">
                <h2>Address</h2>
                <p>No-4/56, 14th Cross Street<br /> Prakash Nagar<br />Thriunindravur <br />Chennai-602024</p>
              </div>
            </div>
            <div className="box">
              <div className="icon"><i><FaPhoneAlt /></i></div>
              <div className="text">
                <h2>Phone</h2>
                <p>+91-9940350960</p>
              </div>
            </div>
            <div className="box">
              <div className="icon"><i><IoMail /></i></div>
              <div className="text">
                <h2>Email</h2>
                <p>rainbowtrust3021@gmail.com</p>
              </div>
            </div>
          </div>
          <div className="contactForm">
            <form ref={form} onSubmit={sendEmail}>
              <h2>Send Meassage</h2>
              <div className="inputBox">
                <input type="text" required name='user_name' />
                <span>Full Name</span>
              </div>
              <div className="inputBox">
                <input type="email" required name='user_email' />
                <span>Email</span>
              </div>
              <div className="inputBox">
                <input type="text" inputMode='numeric' pattern='[0-9]+' required name='user_number' />
                <span>Ph Number</span>
              </div>
              <div className="inputBox">
                <input type="subject" required name='subject' />
                <span>Subject</span>
              </div>
              <div className="inputBox">
                <textarea required name='message'></textarea>
                <span>Type Your Meassage...</span>
              </div>
              <div className="inputBox">
                <input type="submit" required name='' value='Send' />
              </div>
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default Contact

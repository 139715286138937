import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <footer className="bg-dark text-white pt-5 pb-4">
            <div className='container text-center text-md-left'>
                <div className='row text-center text-md-left'>
                    <div className='col-md-3 col-lg-3 col-xl-3 mx-auto mt-3'>
                        <h5 className='footer-title text-uppercase mb-4 font-weight-bold text-warning' id='footer-text-hover'>Rainbow Trust</h5>
                        <p>
                            We envision a world where every individual has the opportunity to thrive, with access to essential resources and a supportive community.
                        </p>
                    </div>
                    <div className=' footer-team col-md-2 col-lg-2 col-xl-2 mx-auto mt-3'>
                        <h5 className='footer-title text-uppercase mb-4 font-weight-bold text-warning'>Legal Information</h5>
                        <p id='footer-text-hover'>
                            <Link to="/PrivacyPolicy" className='text-white' >Privacy Policy</Link>
                        </p>
                        <p>
                            <Link to="/TermsAndConditions" className='text-white'>Terms & Conditions</Link>
                        </p>
                        <p>
                            <Link to="/ReturnPolicy" className='text-white'>Return Policy</Link>
                        </p>
                        <p>
                            <Link to="/Disclaimer" className='text-white'>Disclaimer</Link>
                        </p>
                    </div>
                    <div className='footer-links col-md-3 col-lg-2 col-xl-2 mx-auto mt-3'>
                        <h5 className='footer-title text-uppercase mb-4 font-weight-bold text-warning'>Usefull Links</h5>
                        <p>
                            <Link to="/" className='text-white'>Home</Link>
                        </p>
                        <p>
                            <Link to="/Gallery" className='text-white'>Gallery</Link>
                        </p>
                        <p>
                            <Link to="/Donation" className='text-white'>Donation</Link>
                        </p>
                        <p>
                            <Link to="/About" className='text-white'>About</Link>
                        </p>
                    </div>
                    <div className='footer-contact col-md-4 col-lg-3 col-xl-3 mx-auto mt-3'>
                        <h5 className='footer-title text-uppercase mb-4 font-weight-bold text-warning'>Contact</h5>
                        <p>
                            <i className='fas fa-home mr-3'></i> Thriunindravur, Chennai-602024
                        </p>
                        <p>
                            <i className='fas fa-envelope mr-3'></i> rainbowtrust3021@gmail.com
                        </p>
                        <p>
                            <i className='fas fa-phone mr-3'></i> +91-99403 50960
                        </p>
                    </div>
                </div>
                <hr className='mb-4' />
                <div className=' bottom row align-item-center'>
                    <div className=' copyright col-md-7 col-lg-8'>
                        <p>
                            copyright @2023 all rights reserved by:
                            <Link to='/'>
                                <strong className='text-warning'> The Provider</strong>
                            </Link>
                        </p>
                    </div>
                    <div className=' social-media-icons col-md-5 col-lg-4'>
                        <div className='text-center text-md-right'>
                            <ul className='list-unstyled list-inline'>
                                <li className='list-inline-item'>
                                    <Link to='/' className='btn-floating btn-sm text-white'><i className='fab fa-facebook'></i></Link>
                                </li>
                                <li className='list-inline-item'>
                                    <a href='/' target='_blank' rel="noreferrer" className='btn-floating btn-sm text-white'><i className='fab fa-youtube'></i></a>
                                </li>
                                <li className='list-inline-item'>
                                    <a href="https://wa.me/919940350960" target='_blank' rel="noreferrer" className='btn-floating btn-sm text-white'><i className='fab fa-whatsapp'></i></a>
                                </li>
                                <li className='list-inline-item'>
                                    <a href="https://www.instagram.com/rainbowtrust30/" target='_blank' rel="noreferrer" className='btn-floating btn-sm text-white'><i className='fab fa-instagram'></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className=' copyright col-md-7 col-lg-8'>
                        <p>
                        Website developed by <b>Joshwa</b>. For More Detail 
                            <a href='https://joshwa-portfolio.vercel.app/' target='_blank' rel="noreferrer">
                                <strong className='text-warning'> Click Here </strong>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer

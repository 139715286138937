import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import Footer from './Footer'
const Home = () => {
  return (
    <>
      <Helmet>
        {/* SEO setup */}
        <meta name="title" content="" />
        <meta name="description" content="" />
        <meta name="keywords" content="" />


        <title>RainBow Trust - Trusted NGO in Chennai | Community Support and Donations </title>
        <meta name="description" content="RainBow Trust in Chennai is dedicated to supporting underprivileged communities through various initiatives. Explore our programs and see how your donations can help make a significant impact. Get involved today!" />
        <meta name="keywords" content="RainBow Trust, Trusted NGO in Chennai , Community Support , Donations , Underprivileged Communities , Chennai NGO , Support Initiatives , Make a Significant Impact , Get Involved Today , NGO Programs" />

        <meta name="title" content="Make a Donation to RainBow Trust | Help Communities in Thirunindravur, Chennai " />
        <meta name="description" content="Support RainBow Trust with your donations. Every contribution helps us provide essential services and support to communities in Thirunindravur, Chennai. Donate now to make an impact!" />
        <meta name="keywords" content="Make a Donation, RainBow Trust, Help Communities, Thirunindravur Chennai, Support RainBow Trust, Essential Services, Donations for Chennai, Donate Now, Community Support, Impactful Donation" />

        <meta name="title" content="RainBow Trust in Chennai - Empowering Communities | Get Involved " />
        <meta name="description" content="Discover RainBow Trust in Chennai, dedicated to uplifting communities in need. Learn about our projects and how you can contribute to our mission. Get involved and make a difference!" />
        <meta name="keywords" content="RainBow Trust, Chennai, Empowering communities, Get involved, Uplifting communities, Community projects, Contribute to mission, Make a difference" />

        <meta name="title" content="RainBow Trust - Premier NGO in Thirunindravur, Chennai | Support Our Cause " />
        <meta name="description" content="RainBow Trust, a leading NGO in Thirunindravur, Chennai, is committed to community development and welfare. Join us in our mission to bring positive change. Support us through donations and volunteer opportunities." />
        <meta name="keywords" content="RainBow Trust, NGO Thirunindravur, NGO Chennai, Community development, Welfare NGO, Positive change, Support our cause, Donations, Volunteer opportunities, Leading NGO" />

        <meta name="title" content="RainBow Trust - Leading NGO in Thirunindravur, Chennai | Donate & Volunteer " />
        <meta name="description" content="Join RainBow Trust, a top NGO in Thirunindravur, Chennai, focused on uplifting communities. Support our cause through donations and volunteer work. Help us make a lasting impact. Contribute today!" />
        <meta name="keywords" content=" RainBow Trust, Leading NGO Thirunindravur, NGO Chennai, Donate to NGO Chennai, Volunteer NGO Chennai, Community Upliftment NGO, Support NGO Chennai, Donate and Volunteer Chennai, Make a lasting impact NGO, Contribute to NGO Chennai" />

        <meta name="title" content="Donate to RainBow Trust | Support Our Mission in Thirunindravur, Chennai " />
        <meta name="description" content="Your donation to RainBow Trust can transform lives in Thirunindravur, Chennai. Support our initiatives aimed at community development and welfare. Every contribution counts. Donate now and be a part of the change!" />
        <meta name="keywords" content="Donate to RainBow Trust, RainBow Trust donation, Support RainBow Trust, Thirunindravur charity, Chennai community development, Thirunindravur welfare initiatives, Community welfare Chennai, Transform lives Thirunindravur, Charity donation Chennai, Contribute to RainBow Trust, Donate now Thirunindravur, Support community development Chennai" />

        <meta name="title" content="RainBow Trust in Chennai - Community Empowerment | How to Help " />
        <meta name="description" content="Discover RainBow Trust's efforts in Chennai to empower and uplift local communities. Learn about our programs and find out how you can contribute through donations and volunteer opportunities. Make a difference with us!" />
        <meta name="keywords" content="RainBow Trust Chennai, Community Empowerment, How to Help RainBow Trust, Chennai Community Programs, Empower Local Communities, Donations for Community Programs, Volunteer Opportunities Chennai, Support RainBow Trust, Uplift Local Communities, Make a Difference in Chennai" />

        <meta name="title" content="Support RainBow Trust in Thirunindravur, Chennai | Donate & Volunteer Today " />
        <meta name="description" content="RainBow Trust in Thirunindravur, Chennai, strives for community welfare. Join us in making a difference through donations and volunteer efforts. Support our initiatives to create a brighter future." />
        <meta name="keywords" content="RainBow Trust, Thirunindravur, Chennai, community welfare, donate, volunteer, support initiatives, brighter future, charity, non-profit organization, community support, welfare programs, donation efforts, volunteer opportunities, make a difference" />

        <meta name="title" content="RainBow Trust Chennai | Empowering Communities through Action " />
        <meta name="description" content="RainBow Trust in Chennai is dedicated to empowering communities through impactful projects. Explore our initiatives and discover how you can contribute to our mission of positive change. These meta titles and descriptions are crafted to align with Google's SEO guidelines, focusing on clarity, relevance, and encouraging user engagement." />
        <meta name="keywords" content="RainBow Trust Chennai, Empowering Communities, Impactful Projects, Community Initiatives, Positive Change, Contribute to Mission, Community Empowerment, Chennai NGO, Social Impact, Nonprofit Organization" />

        <meta name="title" content="Support RainBow Trust: NGO in Thirunindravur, Chennai | Donate & Volunteer " />
        <meta name="description" content="Join RainBow Trust, a dedicated NGO in Thirunindravur, Chennai, committed to community welfare. Contribute through donations and volunteer opportunities to help us make a meaningful impact." />
        <meta name="keywords" content="RainBow Trust, NGO Thirunindravur, NGO Chennai, community welfare, donate to NGO, volunteer opportunities, Chennai community support, Thirunindravur charity, donate and volunteer, community impact, NGO donations, NGO volunteer work, Chennai NGO support" />

        <meta name="title" content="Donate to RainBow Trust: Empowering Thirunindravur, Chennai " />
        <meta name="description" content="Support RainBow Trust's initiatives in Thirunindravur, Chennai, through your donations. Your support helps us empower communities and create lasting change. Donate today and be part of our mission!" />
        <meta name="keywords" content="Donate to RainBow Trust, RainBow Trust Thirunindravur Chennai, Support RainBow Trust initiatives, Empower communities Chennai, Donate today RainBow Trust, Donate for community empowerment, Support charity Chennai, Thirunindravur community projects, RainBow Trust donation impact, Chennai NGO donations" />

        <meta name="title" content="RainBow Trust: Community Support in Chennai " />
        <meta name="description" content="Discover RainBow Trust's efforts in Chennai, focused on community support and development. Learn how you can get involved through donations and volunteering. Together, we can make a difference!" />
        <meta name="keywords" content="Rainbow Trust Chennai, Community support Chennai, Volunteer opportunities Chennai, Donate to Rainbow Trust, Chennai development initiatives, Chennai charity organization, Support local communities, Volunteer for a cause, Donate to Chennai NGO, Community development Chennai" />
      </Helmet>
      <div id="hero-carousel" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#hero-carousel" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#hero-carousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#hero-carousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div>

        <div className="carousel-inner">
          <div className="carousel-item active c-item">
            <img src={require("./img/carousel-1.jpeg")} className="d-block w-100 c-img" alt="Slide 1" id='carousel-style'/>
            <div className="carousel-caption top-0 mt-4">
              <h1 className="display-1 fw-bolder text-capitalize">They need your help now</h1>
              <p className="mt-5 fs-3 text-uppercase">GIVING IS NOT JUST ABOUT MAKING A DONATION. IT IS ABOUT MAKING A DIFFERENCE</p>
              <Link to="/Donation">
                <button className="carousel-btn btn btn-primary px-4 py-2 fs-5 mt-5">Donate</button>
              </Link>
            </div>
          </div>
          <div className="carousel-item c-item">
            <img src={require("./img/carousel-2.jpeg")} className="d-block w-100 c-img" alt="Slide 2" id='carousel-style'/>
            <div className="carousel-caption top-0 mt-4">
              <p className="display-1 fw-bolder text-capitalize">Beauty in simplicity</p>
              <p className="text-uppercase fs-3 mt-5">WE MAKE A LIVING BY WHAT WE GET. WE MAKE A LIFE BY WHAT WE GIVE</p>
              <Link to="/Gallery">
                <button className="carousel-btn btn btn-primary px-4 py-2 fs-5 mt-5">Gallery</button>
              </Link>
            </div>
          </div>
          <div className="carousel-item c-item">
            <img src={require("./img/carousel-3.jpeg")} className="d-block w-100 c-img" alt="Slide 3" id='carousel-style'/>
            <div className="carousel-caption top-0 mt-4">
              <p className="display-1 fw-bolder text-capitalize">GET IN TOUCH WITH US</p>
              <p className="text-uppercase fs-3 mt-5">ALONE WE CAN DO LITTLE; TOGETHER WE CAN DO SO MUCH.</p>
              <Link to="/Contact">
                <button className=" carousel-btn btn btn-primary px-4 py-2 fs-5 mt-5">Contact</button>
              </Link>
            </div>
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#hero-carousel" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#hero-carousel" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      <div className='heading'>
        <h1>
          ABOUT THE ORGANIZATION
        </h1>
      </div>

      <div className="about-container">
        <section className="about">
          <div className="about-image">
            <img src={require('./img/c1.png')} alt="" />
          </div>
          <div className="about-content">
            <p>
              Established in 2021 and officially recognized under the Trust Act of 1882, our Rainbow Trust emerged with a noble purpose and a steadfast commitment to serving humanity. Bearing registration number IV/283/2021/Bk.IV, granted on the 26th of October 2021, we embarked on our journey with a vision to make a meaningful impact in the spheres of education, environmental conservation, plantation drives, and the overall welfare of society. As we stand at the crossroads of history, grappling with challenges like poverty and illiteracy, particularly among marginalized communities, we believe that concerted efforts from both governmental bodies and non-governmental organizations (NGOs) like ours hold the key to unlocking solutions. </p>
          </div>
          <p>
            It is our firm belief that by working tirelessly and collaboratively we can pave the way towards a brighter and more equitable future for all The Rainbow Trust represents the aspirations of a vibrant community of young and energetic social workers driven by a shared dream of creating positive change. At the helm of this endeavor is our esteemed founder Mr. Manikandan, whose visionary leadership continues to inspire and guide us in our mission. With dedication, passion and a collective spirit, we are determined to make a lasting difference in the lives of those we serve.
          </p>
        </section>
      </div>

      <div className='heading'>
        <h1>Our Trustees</h1>
      </div>

      <div className="card-container">

        <div className="card">
          <img src={require('./img/Manikandan.jpeg')} alt="" />
          <div className="card-content">
            <h3>Mr. N.K. Manikandan</h3>
            <h4>Founder</h4>
            <p>
              He is a dedicated managing trustee who leads with integrity, ensuring the trust's mission is fulfilled effectively and ethically.
            </p>
          </div>
        </div>

        <div className="card">
          <img src={require('./img/Velmurugan.jpg')} alt="" />
          <div className="card-content">
            <h3>Mr. VelMurugan</h3>
            <h4>Managing Trustee</h4>
            <p>
              He is a dedicated managing trustee who leads with integrity, ensuring the trust's mission is fulfilled effectively and ethically.
            </p>
          </div>
        </div>

        <div className="card">
          <img src={require('./img/Gomathy-pic.jpg')} alt="" />
          <div className="card-content">
            <h3>Mrs. Gomathy</h3>
            <h4>Vice President</h4>
            <p>
              She is a diligent vice president, provides strategic direction, and fosters growth while upholding the values of the trust.
            </p>
          </div>
        </div>

        <div className="card">
          <img src={require('./img/Balaji-img.jpg')} alt="" />
          <div className="card-content">
            <h3>Mr. Balaji</h3>
            <h4>Treasurer</h4>
            <p>
              He is a meticulous treasurer and manages finances prudently, ensuring transparency and accountability in all financial transactions of the trust.
            </p>
          </div>
        </div>

        <div className="card">
          <img src={require('./img/Yuvaraj.jpg')} alt="" />
          <div className="card-content">
            <h3>Mr. Yuvaraj</h3>
            <h4>Joint Treasurer</h4>
            <p>
              He is a dedicated joint treasurer and collaborates effectively to manage finances with transparency and precision for the trust's operations.
            </p>
          </div>
        </div>

        <div className="card">
          <img src={require('./img/Charnaya.jpg')} alt="" />
          <div className="card-content">
            <h3>Mrs. Charnaya</h3>
            <h4>Vice Secretary</h4>
            <p>
              She is a vice secretary and adeptly supports administrative functions, facilitating efficient communication and organisational tasks within the trust's framework.
            </p>
          </div>
        </div>

        <div className="card">
          <img src={require('./img/Lokesh.jpeg')} alt="" />
          <div className="card-content">
            <h3>Mr. Lokesh</h3>
            <h4>Joint Secretary</h4>
            <p>
              He is a joint secretary and adeptly assists in administrative tasks, contributing to the efficient functioning and communication within the trust's operations.
            </p>
          </div>
        </div>

        <div className="card">
          <img src={require('./img/Sathish Kumar.jpg')} alt="" />
          <div className="card-content">
            <h3>Mr. Sathish Kumar</h3>
            <h4>Member</h4>
            <p>
              He is a committed member and actively contributes expertise and support to further the objectives and initiatives of the trust.
            </p>
          </div>
        </div>

        <div className="card">
          <img src={require('./img/Hari Priya.jpg')} alt="" />
          <div className="card-content">
            <h3>Ms. Hari Priya</h3>
            <h4>Member</h4>
            <p>
              She is a dedicated member and contributes valuable insights and support, enriching the endeavours and impact of the trust.
            </p>
          </div>
        </div>
      </div>

      <div className='heading'>
        <h1>
          OUR MOTTO
        </h1>
      </div>

      <div className="about-container">
        <section className="about">
          <div className="about-content">
            <p>
              Established in 2021 and officially recognized under the Trust Act of 1882, our Rainbow Trust emerged with a noble purpose and a steadfast commitment to serving humanity. Bearing registration number IV/283/2021/Bk.IV, granted on the 26th of October 2021, we embarked on our journey with a vision to make a meaningful impact in the spheres of education, environmental conservation, plantation drives, and the overall welfare of society. As we stand at the crossroads of history, grappling with challenges like poverty and illiteracy, particularly among marginalized communities, we believe that concerted efforts from both governmental bodies and non-governmental organizations (NGOs) like ours hold the key to unlocking solutions. </p>
          </div>
          <div className="about-image">
            <img src={require('./img/OUR MOTTO.png')} alt="" />
          </div>
        </section>
      </div>
      <Footer />
    </>
  )
}

export default Home 

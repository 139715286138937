import React from 'react'

const OurProject = ({data}) => {
    return (
        <>
            <div className='heading'>
                <h1>
                    OUR PROJECTS
                </h1>
            </div>

            <div className="about-container">
                <section className="about">
                    <div className="about-content" id='about-image-id'>
                        <p>
                            We're dedicated to combating hunger by distributing 500 food packets monthly to needy individuals and children,
                            ensuring they have access to nutritious meals for their well-being.
                        </p>
                    </div>
                    <div className="about-image" >
                        <img src={require('./img/OUR PROJECTS 1.jpg')} alt="" />
                    </div>
                </section>
            </div>
            <div className="line"></div>
            <div className="about-container">
                <section className="about">
                    <div className="about-image" >
                        <img src={require('./img/OUR PROJECTS 2.jpg')} alt="" />
                    </div>
                    <div className="about-content" id='about-image-id'>
                        <p>
                            We support government school children by supplying essential educational materials such as
                            notebooks, pens, pencils, geometric boxes, and crayons. Ensuring access to these necessities
                            empowers children to engage fully in their education and equips them for a brighter future.
                        </p>
                    </div>
                </section>
            </div>
            <div className="line"></div>
            <div className="about-container">
                <section className="about">
                    <div className="about-content" id='about-image-id'>
                        <p>
                            During the COVID-19 pandemic, we extended support by providing food to roadside individuals
                            facing hardships, ensuring no one went hungry during these challenging times.
                        </p>
                    </div>
                    <div className="about-image" >
                        <img src={require('./img/OUR PROJECTS 3.jpg')} alt="" />
                    </div>
                </section>
            </div>
            <div className="line"></div>
            <div className="about-container">
                <section className="about">
                    <div className="about-image" >
                        <img src={require('./img/OUR PROJECTS 4.jpg')} alt="" />
                    </div>
                    <div className="about-content" id='about-image-id'>
                        <p>
                            During flood and rainy seasons, we offer sustenance by distributing biscuits, bread, and food
                            to people and children residing on the roadside. Our aim is to alleviate hunger and provide comfort
                            during times of natural calamities.
                        </p>
                    </div>
                </section>
            </div>
            <div className="line"></div>
            <div className="about-container">
                <section className="about">
                    <div className="about-content" id='about-image-id'>
                        <p>
                            We ensure the welfare of street dogs and cows by providing them with food and vegetables at least
                            once every two months, acknowledging their importance in our ecosystem.
                        </p>
                    </div>
                    <div className="about-image" >
                        <img src={require('./img/OUR PROJECTS 5.jpg')} alt="" />
                    </div>
                </section>
            </div>
            <div className="line"></div>
            <div className="about-container">
                <section className="about">
                    <div className="about-image" >
                        <img src={require('./img/OUR PROJECTS 6.jpg')} alt="" />
                    </div>
                    <div className="about-content" id='about-image-id'>
                        <p>
                            We've distributed over 100 pairs of slippers to roadside individuals,
                            recognizing the importance of basic footwear in promoting comfort and dignity among those in need.
                        </p>
                    </div>
                </section>
            </div>
            <div className="line"></div>
            <div className="about-container">
                <section className="about">
                    <div className="about-content" id='about-image-id'>
                        <p>
                            We expressed our gratitude to frontline workers by providing them with sarees and sweets,
                            acknowledging their unwavering dedication and hard work throughout the year.
                        </p>
                    </div>
                    <div className="about-image" >
                        <img src={require('./img/OUR PROJECTS 7.jpg')} alt="" />
                    </div>
                </section>
            </div>
            <div className="line"></div>
            <div className="about-container">
                <section className="about">
                    <div className="about-image" >
                        <img src={require('./img/OUR PROJECTS 8.jpg')} alt="" />
                    </div>
                    <div className="about-content" id='about-image-id'>
                        <p>
                            During the COVID-19 pandemic, we supported police personnel by providing masks, soap,
                            sanitizer, and gloves, recognizing their crucial role in safeguarding public health and safety.
                        </p>
                    </div>
                </section>
            </div>

            <div className="about-container">
                <section className="about">
                    <div className="about-content" id='about-image-id'>
                        <p>
                            We've implemented the DFW Project to cover villages and schools,
                            alongside the S2S initiative aimed at enhancing educational opportunities for students,
                            fostering holistic development in rural areas.
                        </p>
                    </div>
                    <div className="about-image" >
                        <img src={require('./img/OUR PROJECTS 9.jpg')} alt="" />
                    </div>
                </section>
            </div>
            <div className="line"></div>
            <div className="about-container">
                <section className="about">
                    <div className="about-image" >
                        <img src={require('./img/OUR PROJECTS 10.jpg')} alt="" />
                    </div>
                    <div className="about-content" id='about-image-id'>
                        <p>
                            DFW, we joined with the village people and cleaned their streets.
                            This will show unity, and we can make anything possible. Provided
                            Rice, dresses, dal, etc. as a complement for them. Covered almost 10
                            villages in this project.
                        </p>
                    </div>
                </section>
            </div>
            <div className="line"></div>
            <div className="about-container">
                <section className="about">
                    <div className="about-content" id='about-image-id'>
                        <p>
                            The S2S program motivates schoolchildren to excel in their studies.
                            By supplying essential materials like notebooks, stationery, and even toys,
                            we aim to incentivize regular attendance and foster a positive learning environment.
                        </p>
                    </div>
                    <div className="about-image" >
                        <img src={require('./img/OUR PROJECTS 11.jpg')} alt="" />
                    </div>
                </section>
            </div>
            <div className="line"></div>
            <div className="about-container">
                <section className="about">
                    <div className="about-image" >
                        <img src={require('./img/OUR PROJECTS 12.jpg')} alt="" />
                    </div>
                    <div className="about-content" id='about-image-id'>
                        <p>
                            We have provided college students with bags containing all the necessary materials,
                            serving as an encouragement, particularly for first-year students,
                            as they embark on their academic journey.
                        </p>
                    </div>
                </section>
            </div>
            <div className="line"></div>
            <div className="about-container">
                <section className="about">
                    <div className="about-content" id='about-image-id'>
                        <p>
                            We rejoiced in Diwali festivities alongside members of the tribal community,
                            spreading cheer by distributing sweets and new dresses in both Veerapuram and Minjur.
                        </p>
                    </div>
                    <div className="about-image" >
                        <img src={require('./img/OUR PROJECTS 13.jpg')} alt="" />
                    </div>
                </section>
            </div>
            <div className="line"></div>
            <div className="about-container">
                <section className="about">
                    <div className="about-image" >
                        <img src={require('./img/OUR PROJECTS 14.jpg')} alt="" />
                    </div>
                    <div className="about-content" id='about-image-id'>
                        <p>
                            We are collecting old clothes, meticulously segregating them, and subsequently distributing them to
                            tribal communities and children in need.
                        </p>
                    </div>
                </section>
            </div>
            <div className="line"></div>
            <div className="about-container">
                <section className="about">
                    <div className="about-content" id='about-image-id'>
                        <p>
                            We are collecting rice and distributing it to the tribal community people,
                            ensuring they receive around 50 kg of rice monthly to help meet their dietary needs.
                        </p>
                    </div>
                    <div className="about-image" >
                        <img src={require('./img/OUR PROJECTS 15.jpg')} alt="" />
                    </div>
                </section>
            </div>
            <div className="line"></div>
            <div className="about-container">
                <section className="about">
                    <div className="about-image" >
                        <img src={require('./img/OUR PROJECTS 16.jpg')} alt="" />
                    </div>
                    <div className="about-content" id='about-image-id'>
                        <p>
                            We provide fruits and health mixes to cancer patients, supporting their nutritional needs and overall
                            well-being during their treatment journey.
                        </p>
                    </div>
                </section>
            </div>
            <div className="line"></div>
            <div className="about-container">
                <section className="about">
                    <div className="about-content" id='about-image-id'>
                        <p>
                        We've equipped handicapped individuals with tailoring machines, 
                        empowering them with skills and opportunities for sustainable livelihoods.
                        </p>
                    </div>
                    <div className="about-image" >
                        <img src={require('./img/OUR PROJECTS 17.jpg')} alt="" />
                    </div>
                </section>
            </div>
            <div className="line"></div>
            <div className="about-container">
                <section className="about">
                    <div className="about-image" >
                        <img src={require('./img/OUR PROJECTS 18.jpg')} alt="" />
                    </div>
                    <div className="about-content" id='about-image-id'>
                        <p>
                        We've distributed groceries to those in need during the COVID-19 pandemic, 
                        ensuring access to essential supplies during challenging times.
                        </p>
                    </div>
                </section>
            </div>
            <div className="line"></div>
            <div className="about-container">
                <section className="about">
                    <div className="about-content" id='about-image-id'>
                        <p>
                        We provide monthly grocery distributions to handicapped individuals and widowed women, 
                        offering essential support to vulnerable members of our community.
                        </p>
                    </div>
                    <div className="about-image" >
                        <img src={require('./img/OUR PROJECTS 19.jpg')} alt="" />
                    </div>
                </section>
            </div>
            <div className="line"></div>
            <div className="about-container">
                <section className="about">
                    <div className="about-image" >
                        <img src={require('./img/OUR PROJECTS 20.jpg')} alt="" />
                    </div>
                    <div className="about-content" id='about-image-id'>
                        <p>
                        We've provided tarpaulins (Tarpai) to village residents, offering protection 
                        and relief during adverse weather conditions or emergencies.
                        </p>
                    </div>
                </section>
            </div>
            <div className="line"></div>
            <div className="about-container">
                <section className="about">
                    <div className="about-content" id='about-image-id'>
                        <p>
                        We engage in occasional celebrations with underprivileged individuals and their children, 
                        spreading joy and fostering a sense of community and belonging.
                        </p>
                    </div>
                    <div className="about-image" >
                        <img src={require('./img/OUR PROJECTS 21.jpg')} alt="" />
                    </div>
                </section>
            </div>
            <div className="line"></div>
            <div className="about-container">
                <section className="about">
                    <div className="about-image" >
                        <img src={require('./img/OUR PROJECTS 22.jpg')} alt="" />
                    </div>
                    <div className="about-content" id='about-image-id'>
                        <p>
                        We conducted an eye campaign and successfully performed eye operations, 
                        improving the vision and quality of life for those in need.
                        </p>
                    </div>
                </section>
            </div>
            <div className="line"></div>
            <div className="about-container">
                <section className="about">
                    <div className="about-content" id='about-image-id'>
                        <p>
                        We've been offering education support to both rural and urban students from economically disadvantaged families, 
                        ensuring they have access to opportunities for academic advancement and a brighter future.
                        </p>
                    </div>
                    <div className="about-image" >
                        <img src={require('./img/OUR PROJECTS 23.jpg')} alt="" />
                    </div>
                </section>
            </div>
        </>
    )
}

export default OurProject

import React, { useState } from 'react'
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { VscChromeClose } from "react-icons/vsc";
import { GrLinkNext } from "react-icons/gr";
import { GrLinkPrevious } from "react-icons/gr";
import Footer from './Footer';
import { Link } from 'react-router-dom';
const Gallery = () => {

  const images = [
    require("./RainbowTrustGallery/l1.jpg"),
    require("./RainbowTrustGallery/l2.jpg"),
    require("./RainbowTrustGallery/l3.jpg"),
    require("./RainbowTrustGallery/l4.jpg"),
    require("./RainbowTrustGallery/l5.jpg"),
    require("./RainbowTrustGallery/l6.jpg"),
    require("./RainbowTrustGallery/l7.jpg"),
    require("./RainbowTrustGallery/l8.jpg"),
    require("./RainbowTrustGallery/l9.jpg"),
    require("./RainbowTrustGallery/l10.jpg"),
    require("./RainbowTrustGallery/l11.jpg"),
    require("./RainbowTrustGallery/l12.jpg"),
    require("./RainbowTrustGallery/l13.jpg"),
    require("./RainbowTrustGallery/l14.jpg"),
    require("./RainbowTrustGallery/l15.jpg"),
    require("./RainbowTrustGallery/l16.jpg"),
    require("./RainbowTrustGallery/l17.jpg"),
    require("./RainbowTrustGallery/l18.jpg"),
    require("./RainbowTrustGallery/l19.jpg"),
    require("./RainbowTrustGallery/l20.jpg"),
    require("./RainbowTrustGallery/l21.jpg"),
    require("./RainbowTrustGallery/l22.jpg"),
    require("./RainbowTrustGallery/l23.jpg"),
    require("./RainbowTrustGallery/l24.jpg"),
    require("./RainbowTrustGallery/l25.jpg"),
    require("./RainbowTrustGallery/l26.jpg"),
    require("./RainbowTrustGallery/l27.jpg"),
    require("./RainbowTrustGallery/l28.jpg"),
    require("./RainbowTrustGallery/l29.jpg"),
    require("./RainbowTrustGallery/l30.jpg"),
    require("./RainbowTrustGallery/l31.jpg"),
    require("./RainbowTrustGallery/l32.jpg"),
    require("./RainbowTrustGallery/l33.jpg"),
    require("./RainbowTrustGallery/l34.jpg"),
    require("./RainbowTrustGallery/l35.jpg"),
    require("./RainbowTrustGallery/l36.jpg"),
    require("./RainbowTrustGallery/l37.jpg"),
    require("./RainbowTrustGallery/l38.jpg"),
    require("./RainbowTrustGallery/l39.jpg"),
    require("./RainbowTrustGallery/l45.jpg"),
    require("./RainbowTrustGallery/l50.jpg"),
    require("./RainbowTrustGallery/l51.jpg"),
    require("./RainbowTrustGallery/l52.jpg"),
    require("./RainbowTrustGallery/l53.jpg"),
    require("./RainbowTrustGallery/l54.jpg"),
    require("./RainbowTrustGallery/l55.jpg"),
    require("./RainbowTrustGallery/l56.jpg"),
    require("./RainbowTrustGallery/l57.jpg"),
    require("./RainbowTrustGallery/l58.jpg"),
    require("./RainbowTrustGallery/l59.jpg"),
    require("./RainbowTrustGallery/l60.jpg"),
    require("./RainbowTrustGallery/l61.jpg"),
    require("./RainbowTrustGallery/l62.jpg"),
    require("./RainbowTrustGallery/l63.jpg"),
    require("./RainbowTrustGallery/l64.jpg"),
    require("./RainbowTrustGallery/l65.jpg"),
    require("./RainbowTrustGallery/l66.jpg"),
    require("./RainbowTrustGallery/l67.jpg"),
    require("./RainbowTrustGallery/l68.jpg"),
    require("./RainbowTrustGallery/l69.jpg"),
    require("./RainbowTrustGallery/l70.jpg"),
    require("./RainbowTrustGallery/l71.jpg"),
    require("./RainbowTrustGallery/l72.jpg"),
    require("./RainbowTrustGallery/l73.jpg"),
    require("./RainbowTrustGallery/l74.jpg"),
    require("./RainbowTrustGallery/l75.jpg"),
    require("./RainbowTrustGallery/l76.jpg"),
    require("./RainbowTrustGallery/l77.jpg"),
    require("./RainbowTrustGallery/l78.jpg"),
    require("./RainbowTrustGallery/l79.jpg"),
    require("./RainbowTrustGallery/l80.jpg"),
    require("./RainbowTrustGallery/l81.jpg"),
    require("./RainbowTrustGallery/l82.jpg"),
    require("./RainbowTrustGallery/l85.jpg"),
    require("./RainbowTrustGallery/l86.jpg"),
    require("./RainbowTrustGallery/l87.jpg"),
    require("./RainbowTrustGallery/l89.jpg"),
    require("./RainbowTrustGallery/l90.jpg"),
    require("./RainbowTrustGallery/l91.jpg"),
    require("./RainbowTrustGallery/l92.jpg"),
    require("./RainbowTrustGallery/l93.jpg"),
    require("./RainbowTrustGallery/l94.jpg"),
    require("./RainbowTrustGallery/l95.jpg"),
    require("./RainbowTrustGallery/l96.jpg"),
    require("./RainbowTrustGallery/l97.jpg"),
    require("./RainbowTrustGallery/l98.jpg"),
    require("./RainbowTrustGallery/l99.jpg"),
    require("./RainbowTrustGallery/l100.jpg"),
    require("./RainbowTrustGallery/i101.jpg"),
    require("./RainbowTrustGallery/i102.jpg"),
    require("./RainbowTrustGallery/i103.jpg"),
    require("./RainbowTrustGallery/i104.jpg"),
    require("./RainbowTrustGallery/i105.jpg"),
    require("./RainbowTrustGallery/i106.jpg"),
    require("./RainbowTrustGallery/i107.jpg"),
    require("./RainbowTrustGallery/i108.jpg"),
    require("./RainbowTrustGallery/i109.jpg"),
    require("./RainbowTrustGallery/i110.jpg"),
    require("./RainbowTrustGallery/i111.jpg"),
    require("./RainbowTrustGallery/i112.jpg"),
    require("./RainbowTrustGallery/i113.jpg"),
    require("./RainbowTrustGallery/i113.jpg"),
    require("./RainbowTrustGallery/i115.jpg"),
    require("./RainbowTrustGallery/i116.jpg"),
    require("./RainbowTrustGallery/i117.jpg"),
    require("./RainbowTrustGallery/i118.jpg"),
    require("./RainbowTrustGallery/i119.jpg"),
    require("./RainbowTrustGallery/i120.jpg"),
    require("./RainbowTrustGallery/i121.jpeg"),
    require("./RainbowTrustGallery/i122.jpeg"),
    require("./RainbowTrustGallery/i123.jpeg"),
    require("./RainbowTrustGallery/i124.jpeg"),
    require("./RainbowTrustGallery/i125.jpeg"),
    require("./RainbowTrustGallery/i126.jpeg"),
    require("./RainbowTrustGallery/i127.jpeg"),
    require("./RainbowTrustGallery/i128.jpeg"),
    require("./RainbowTrustGallery/i129.jpeg"),
    require("./RainbowTrustGallery/i130.jpeg"),
    require("./RainbowTrustGallery/i131.jpeg"),
    require("./RainbowTrustGallery/i132.jpeg"),
    require("./RainbowTrustGallery/i133.jpeg"),
    require("./RainbowTrustGallery/i134.jpeg"),
    require("./RainbowTrustGallery/i135.jpeg"),
    require("./RainbowTrustGallery/i136.jpeg"),
    require("./RainbowTrustGallery/i137.jpeg"),
    require("./RainbowTrustGallery/i138.jpeg"),
    require("./RainbowTrustGallery/i139.jpeg"),
    require("./RainbowTrustGallery/i140.jpeg"),
    require("./RainbowTrustGallery/i141.jpeg"),
    require("./RainbowTrustGallery/i142.jpeg"),
    require("./RainbowTrustGallery/i143.jpeg"),
    require("./RainbowTrustGallery/i144.jpeg"),
    require("./RainbowTrustGallery/i145.jpeg"),
    require("./RainbowTrustGallery/i146.jpeg"),
    require("./RainbowTrustGallery/i147.jpeg"),
    require("./RainbowTrustGallery/i148.jpeg"),
    require("./RainbowTrustGallery/i149.jpeg"),
    require("./RainbowTrustGallery/i150.jpeg"),
    require("./RainbowTrustGallery/i151.jpeg"),
    require("./RainbowTrustGallery/i152.jpeg"),
    require("./RainbowTrustGallery/i153.jpeg"),
    require("./RainbowTrustGallery/i154.jpeg"),
    require("./RainbowTrustGallery/i155.jpeg"),
    require("./RainbowTrustGallery/i156.jpeg"),
    require("./RainbowTrustGallery/i157.jpeg"),
    require("./RainbowTrustGallery/i158.jpeg"),
    require("./RainbowTrustGallery/i159.jpeg"),
    require("./RainbowTrustGallery/i160.jpeg"),
    require("./RainbowTrustGallery/i161.jpeg"),
    require("./RainbowTrustGallery/i162.jpeg"),
    require("./RainbowTrustGallery/i163.jpeg"),
    require("./RainbowTrustGallery/i164.jpeg"),
    require("./RainbowTrustGallery/i165.jpeg"),
    require("./RainbowTrustGallery/i166.jpeg"),
    require("./RainbowTrustGallery/i167.jpeg"),
    require("./RainbowTrustGallery/i168.jpeg"),
    require("./RainbowTrustGallery/i169.jpeg"),
    require("./RainbowTrustGallery/i170.jpeg"),
    require("./RainbowTrustGallery/i171.jpeg"),
    require("./RainbowTrustGallery/i172.jpeg"),
    require("./RainbowTrustGallery/i173.jpeg"),
    require("./RainbowTrustGallery/i174.jpeg"),
    require("./RainbowTrustGallery/i175.jpeg"),
    require("./RainbowTrustGallery/i176.jpeg"),
    require("./RainbowTrustGallery/i177.jpeg"),
    require("./RainbowTrustGallery/i178.jpeg"),
    require("./RainbowTrustGallery/i179.jpeg"),
    require("./RainbowTrustGallery/i180.jpeg"),
    require("./RainbowTrustGallery/i181.jpeg"),
    require("./RainbowTrustGallery/i182.jpeg"),
    require("./RainbowTrustGallery/i183.jpeg"),
    require("./RainbowTrustGallery/i184.jpeg"),
    require("./RainbowTrustGallery/i185.jpeg"),
    require("./RainbowTrustGallery/i186.jpeg"),
    require("./RainbowTrustGallery/i187.jpeg"),
    require("./RainbowTrustGallery/i188.jpeg"),
    require("./RainbowTrustGallery/i189.jpeg"),
    require("./RainbowTrustGallery/i190.jpeg"),
    require("./RainbowTrustGallery/i191.jpeg"),
    require("./RainbowTrustGallery/i192.jpeg"),
    require("./RainbowTrustGallery/i193.jpeg"),
    require("./RainbowTrustGallery/i194.jpeg"),
    require("./RainbowTrustGallery/i195.jpeg"),
    require("./RainbowTrustGallery/i196.jpeg"),
    require("./RainbowTrustGallery/i197.jpeg"),
    require("./RainbowTrustGallery/i198.jpeg"),
    require("./RainbowTrustGallery/i199.jpeg"),
    require("./RainbowTrustGallery/i200.jpeg"),
    require("./RainbowTrustGallery/i201.jpeg"),
    require("./RainbowTrustGallery/i202.jpeg"),
    require("./RainbowTrustGallery/i203.jpeg"),
    require("./RainbowTrustGallery/i204.jpeg"),
    require("./RainbowTrustGallery/i205.jpeg"),
    require("./RainbowTrustGallery/i206.jpeg"),
    require("./RainbowTrustGallery/i207.jpeg"),
    require("./RainbowTrustGallery/i208.jpeg"),
    require("./RainbowTrustGallery/i209.jpeg"),
    require("./RainbowTrustGallery/i210.jpeg"),
    require("./RainbowTrustGallery/i211.jpeg"),
    require("./RainbowTrustGallery/i212.jpeg"),
    require("./RainbowTrustGallery/i213.jpeg"),
    require("./RainbowTrustGallery/i213.jpeg"),
    require("./RainbowTrustGallery/i215.jpeg"),
    require("./RainbowTrustGallery/i216.jpeg"),
    require("./RainbowTrustGallery/i217.jpeg"),
    require("./RainbowTrustGallery/i218.jpeg"),
    require("./RainbowTrustGallery/i219.jpeg"),
    require("./RainbowTrustGallery/i220.jpeg"),
    require("./RainbowTrustGallery/i221.jpeg"),
    require("./RainbowTrustGallery/i222.jpeg"),
    require("./RainbowTrustGallery/i223.jpeg"),
    require("./RainbowTrustGallery/i224.jpeg"),
    require("./RainbowTrustGallery/i225.jpeg"),
    require("./RainbowTrustGallery/i226.jpeg"),
    require("./RainbowTrustGallery/i227.jpeg"),
    require("./RainbowTrustGallery/i228.jpeg"),
    require("./RainbowTrustGallery/i229.jpeg"),
    require("./RainbowTrustGallery/i230.jpeg"),
    require("./RainbowTrustGallery/i231.jpeg"),
    require("./RainbowTrustGallery/i232.jpeg"),
    require("./RainbowTrustGallery/i233.jpeg"),
    require("./RainbowTrustGallery/i234.jpeg"),
    require("./RainbowTrustGallery/i235.jpeg"),
    require("./RainbowTrustGallery/i236.jpeg"),
    require("./RainbowTrustGallery/i237.jpeg"),
    require("./RainbowTrustGallery/i238.jpeg"),
    require("./RainbowTrustGallery/i239.jpeg"),
    require("./RainbowTrustGallery/i240.jpeg"),
    require("./RainbowTrustGallery/i241.jpeg"),
    require("./RainbowTrustGallery/i242.jpeg"),
    require("./RainbowTrustGallery/i243.jpeg"),
    require("./RainbowTrustGallery/i244.jpeg"),
    require("./RainbowTrustGallery/i245.jpeg"),
    require("./RainbowTrustGallery/i246.jpeg"),
    require("./RainbowTrustGallery/i247.jpeg"),
    require("./RainbowTrustGallery/i248.jpeg"),
    require("./RainbowTrustGallery/i249.jpeg"),
    require("./RainbowTrustGallery/i250.jpeg"),
    require("./RainbowTrustGallery/i251.jpeg"),
    require("./RainbowTrustGallery/i252.jpeg"),
    require("./RainbowTrustGallery/i253.jpeg"),
    require("./RainbowTrustGallery/i254.jpeg"),
    require("./RainbowTrustGallery/i255.jpeg"),
    require("./RainbowTrustGallery/i256.jpeg"),
    require("./RainbowTrustGallery/i257.jpeg"),
    require("./RainbowTrustGallery/i258.jpeg"),
    require("./RainbowTrustGallery/i259.jpeg"),
    require("./RainbowTrustGallery/i260.jpeg"),
    require("./RainbowTrustGallery/i261.jpeg"),
    require("./RainbowTrustGallery/i262.jpeg"),
    require("./RainbowTrustGallery/i263.jpeg"),
    require("./RainbowTrustGallery/i264.jpeg"),
    require("./RainbowTrustGallery/i265.jpeg"),
    require("./RainbowTrustGallery/i266.jpeg"),
    require("./RainbowTrustGallery/i267.jpeg"),
    require("./RainbowTrustGallery/i268.jpeg"),
    require("./RainbowTrustGallery/i269.jpeg"),
    require("./RainbowTrustGallery/i270.jpeg"),
    require("./RainbowTrustGallery/i271.jpeg"),
    require("./RainbowTrustGallery/i272.jpeg"),
    require("./RainbowTrustGallery/i273.jpeg"),
    require("./RainbowTrustGallery/i274.jpeg"),
    require("./RainbowTrustGallery/i275.jpeg"),
    require("./RainbowTrustGallery/i276.jpeg"),
    require("./RainbowTrustGallery/i277.jpeg"),
    require("./RainbowTrustGallery/i278.jpeg"),
    require("./RainbowTrustGallery/i279.jpeg"),
    require("./RainbowTrustGallery/i280.jpeg"),
    require("./RainbowTrustGallery/i281.jpeg"),
    require("./RainbowTrustGallery/i282.jpeg"),
    require("./RainbowTrustGallery/i283.jpeg"),
    require("./RainbowTrustGallery/i284.jpeg"),
    require("./RainbowTrustGallery/i285.jpeg"),
    require("./RainbowTrustGallery/i286.jpeg"),
    require("./RainbowTrustGallery/i287.jpeg"),
    require("./RainbowTrustGallery/i288.jpeg"),
    require("./RainbowTrustGallery/i289.jpeg"),
    require("./RainbowTrustGallery/i290.jpeg"),
    require("./RainbowTrustGallery/i291.jpg"),

  ]

  const [data, setData] = useState({ img: '', i: 0 })

  const viewImage = (img, i) => {
    setData({ img, i });
  }
  const imgAction = (action) => {
    let i = data.i;
    if (action === 'next-img') {
      setData({ img: images[i + 1], i: i + 1 });
    }
    if (action === 'previous-img') {
      setData({ img: images[i - 1], i: i - 1 });
    }
    if (!action) {
      setData({ img: '', i: 0 });
    }
  }

  return (
    <>
      {data.img &&
        <div className='img-popup-background'>
          <p className='close-button' onClick={() => imgAction()}><VscChromeClose /></p>
          <div>
            <p className='previous-button ' onClick={() => imgAction('previous-img')}><GrLinkPrevious /></p>
          </div>
          <img className='img-popup' src={data.img} alt="" />
          <p className="next-button " onClick={() => imgAction('next-img')}><GrLinkNext /></p>
        </div>
      }
      <div style={{ padding: "10px" }}>
        <div className="entry-header">
          <div className='nv-title-meta-wrap' style={{ padding: "10px" }}>
            <div className='container' style={{ padding: "10px" }}>
              <h1 className='entry-title'>Gallery</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item text-white "><Link to="/" className="text-white">Home</Link></li>
                  <li className="breadcrumb-item text-white active" aria-current="page"> Gallery</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
        >
          <Masonry gutter='20px'>
            {images.map((image, i) => (
              <img className='img-display'
                key={i}
                src={image}
                alt=""
                onClick={() => viewImage(image, i)}
              />
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </div>
      <Footer />
    </>
  )
}

export default Gallery


import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Success from './component/Success';
import Failure from './component/Failure';
import Phonepe from './file/phonepe/Phonepe';
import Contact from './file/Contact';
import About from './file/About';
import Gallery from './file/Gallery';
import Home from './file/Home';
import NavBar from './file/NavBar';
import PrivacyPolicy from './file/PrivacyPolicy';
import TermsAndConditions from './file/TermsAndConditions';
import Disclaimer from './file/Disclaimer';
import ReturnPolicy from './file/ReturnPolicy';
import ScrollToTop from './file/ScrollToTop';

function App() {
  return (
    <BrowserRouter>
      <div className='main'>
        <ScrollToTop />
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route exact path='/Donation' element={<Phonepe />} />
          <Route exact path='/success' element={<Success />} />
          <Route exact path='/failure' element={<Failure />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/About" element={<About />} />
          <Route path="/Contact" element={<Contact />} />
          <Route exact path='/success' element={<Success />} />
          <Route exact path='/failure' element={<Failure />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
          <Route path="/Disclaimer" element={<Disclaimer />} />
          <Route path="/ReturnPolicy" element={<ReturnPolicy />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;

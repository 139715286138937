import React from 'react'
import { Link } from 'react-router-dom'
import Footer from './Footer'
import OurProject from './OurProject'

const About = () => {
  return (
    <>
      <div style={{ padding: "10px" }}>
        <div className="entry-header" >
          <div className='nv-title-meta-wrap' style={{ padding: "10px" }}>
            <div className='' style={{ padding: "10px" }}>
              <h1 className='entry-title'>About</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/" className="text-white">Home</Link></li>
                  <li className="breadcrumb-item active text-white" aria-current="page"> About</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div className='abt-org-page'>
        <div className='heading'>
          <h1>
            OUR ACTIVITIES
          </h1>
        </div>

        <div className="about-container " id='about-container-id'>
          <section className="about" id="about-id">
            <div className="about-content">
              <p id='text-align-id'>
                It is both a privilege and a source of pride for our country to host a non-profit organization of such significance, and we are deeply honored to contribute to its noble cause. At Development Association for Integrated Services, we are not only happy but profoundly fulfilled to be an integral part of this transformative journey. We are keen to highlight and celebrate the remarkable array of services that our organization tirelessly provides for the betterment of society. Since our inception, we have steadfastly dedicated ourselves to uplifting humanity and promoting its advancement.
              </p>
            </div>
            <div className="about-image">
              <img src={require('./img/OUR ACTIVITIES IMG.png')} alt="" />
            </div>
            <div className="about-content">
              <p id='text-align-id'>
                From initiatives aimed at healthcare improvement to educational empowerment, livelihood enhancement, and community development, our organization has been at the forefront of multifaceted service delivery. We take immense pride in our commitment to serving mankind and facilitating its progress towards greater prosperity and well-being. Every endeavor, every project, and every act of service is a testament to our unwavering dedication to the betterment of society. As we reflect on our journey thus far, we are inspired to continue our efforts with renewed vigor, driven by the belief that together, we can create a world where every individual has the opportunity to thrive and prosper.
              </p>
            </div>
          </section>
        </div>

        <div className='heading'>
          <h1>
            OBJECTIVES
          </h1>
        </div>
        <div className="about-container">
          <section className="about">
            <div className="about-content" id='about-image-id'>
              <p>
                Our objectives encompass a broad spectrum of compassionate initiatives, including the establishment of an old age home, provision of educational support to underprivileged children, assistance to orphanages, and conducting medical camps to promote public health. These endeavors reflect our unwavering commitment to addressing pressing social issues and improving the overall well-being of our communities.
              </p>
            </div>
            <div className="about-image" >
              <img src={require('./img/Old age home OBJECTIVES.png')} alt="" />
            </div>
          </section>
        </div>
        <div className="line"></div>
        <div className="about-container">
          <section className="about">
            <div className="about-image" >
              <img src={require('./img/unemployment OBJECTIVES.png')} alt="" />
            </div>
            <div className="about-content" id='about-image-id'>
              <p>
                Our mission extends to providing essential services for diverse groups, including the blind, deaf, unemployed women and men, widows, as well as those who are physically, mentally, and socially handicapped. Through targeted assistance and support programs, we aim to empower individuals facing various challenges, fostering inclusivity and enhancing their quality of life.
              </p>
            </div>

          </section>
        </div>
        <div className="line"></div>
        <div className="about-container">
          <section className="about">
            <div className="about-content" id='about-image-id'>
              <p>
                Our mission extends to aiding unorganized, underprivileged construction, and temporary workers along with their families. By providing support tailored to their unique needs, we aim to alleviate their hardships and foster a more inclusive society. Through our concerted efforts, we strive to uplift marginalized communities and ensure they receive the assistance and resources they require for a better quality of life.
              </p>
            </div>
            <div className="about-image" >
              <img src={require('./img/families OBJECTIVES.png')} alt="" />
            </div>
          </section>
        </div>
        <div className="line"></div>
        <div className="about-container">
          <section className="about">
            <div className="about-image" >
              <img src={require('./img/rural areas OBJECTIVES.png')} alt="" />
            </div>
            <div className="about-content" id='about-image-id'>
              <p>
                Our mission is to uplift the living standards of marginalized populations, including the impoverished, downtrodden, and underprivileged individuals residing in urban slums, rural areas, and among the transgender community. Through targeted interventions and support programs, we aim to foster inclusivity and empower these communities to lead dignified and fulfilling lives.
              </p>
            </div>
          </section>
        </div>

        <div className='heading'>
          <h1>
            STRATEGY
          </h1>
        </div>
        <div className="about-container">
          <section className="about">
            <div className="about-image">
              <img className='STRATEGY-img' src={require('./img/STRATEGY.jpg')} alt="" />
            </div>
            <div className="about-content">
              <p>
                We are committed to implementing sustainable community-based initiatives aimed at fostering the holistic development of our target groups. Through comprehensive efforts spanning education, healthcare, economic empowerment, and social development, we strive to create lasting positive change. By addressing the multifaceted needs of our communities in a collaborative and inclusive manner, we aim to build resilient and thriving societies where every individual has the opportunity to flourish and contribute to collective progress.
              </p>
            </div>
          </section>
        </div>

        <div className='heading'>
          <h1>
            EDUCATION
          </h1>
        </div>

        <div className="about-container " id='about-container-id'>
          <section className="about" id="about-id">
            <div className="about-content">
              <p id='text-align-id'>
                In today`s world where one can reach the other corner of the world without displacement, education has become an essential part of human life. Even if not that advanced, basic knowledge is necessary for an individual to be aware of society and to thrive in this journey called life. But it is not that easy as there stand many social barriers in the way, of these the most significant is poverty. The concept of the role a child should play in society is still not clear, for example even now poor people take them as a source of income resulting in major issues such as child labor and child trafficking and this deteriorates the child`s lifestyle and thinking. This vicious cycle then is carried on
              </p>
            </div>
            <div className="about-image" id='about-image-id'>
              <div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src={require('./img/EDUCATION 1.jpg')} class="d-block w-100" alt="..." />
                  </div>
                  <div class="carousel-item">
                    <img src={require('./img/EDUCATION 2.jpg')} class="d-block w-100" alt="..." />
                  </div>
                  <div class="carousel-item">
                    <img src={require('./img/EDUCATION 3.jpg')} class="d-block w-100" alt="..." />
                  </div>
                  <div class="carousel-item">
                    <img src={require('./img/EDUCATION 4.jpg')} class="d-block w-100" alt="..." />
                  </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </section>
        </div>


        <div className='heading'>
          <h1>
            ENVIRONMENT
          </h1>
        </div>

        <div className="about-container " id='about-container-id'>
          <section className="about" id="about-id">
            <div className="about-content">
              <p id='text-align-id'>
                In today`s world where one can reach the other corner of the world without displacement, education has become an essential part of human life. Even if not that advanced, basic knowledge is necessary for an individual to be aware of society and to thrive in this journey called life. But it is not that easy as there stand many social barriers in the way, of these the most significant is poverty. The concept of the role a child should play in society is still not clear, for example even now poor people take them as a source of income resulting in major issues such as child labor and child trafficking and this deteriorates the child`s lifestyle and thinking. This vicious cycle then is carried on
              </p>
            </div>

            <div className="about-image" id='about-image-id'>
              <div id="carouselExampleControls" class="carousel slide" data-bs-ride="true" data-bs-interval="5000">
                <div class="carousel-inner">
                  <div className="carousel-item active">
                    <img src={require('./img/ENVIRONMENT 1.jpg')} className="d-block w-100" alt="..." />
                  </div>
                  <div className="carousel-item">
                    <img src={require('./img/ENVIRONMENT 2.jpg')} className="d-block w-100" alt="..." />
                  </div>
                  <div className="carousel-item">
                    <img src={require('./img/ENVIRONMENT 3.jpg')} className="d-block w-100" alt="..." />
                  </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
            <div className="about-content">
              <p id='text-align-id'>
                Water, air, and land are bestowed upon us as invaluable gifts from nature, deserving of utmost reverence rather than exploitation. Striking a delicate balance between environmental preservation and economic growth is imperative for our collective well-being. Many find solace in the serene embrace of natural landscapes, recognizing the profound connection between our inner peace and the environment. Yet, despite its immeasurable value, we often overlook the intrinsic worth of our surroundings. It's high time we acknowledge the priceless contribution of the environment to our lives and commit to safeguarding its integrity for generations to come.
              </p>
            </div>
          </section>
        </div>

        <div className='heading'>
          <h1>
            HOMELESS
          </h1>
        </div>

        <div className="about-container " id='about-container-id'>
          <section className="about" id="about-id">
            <div className="about-content">
              <p id='text-align-id'>
                Our NGO has dedicated itself tirelessly to aiding the most vulnerable members of our society. Through our concerted efforts, we extend a helping hand to those who struggle to meet their basic daily needs. Our assistance takes various forms, including food and clothing distribution, alongside other small yet impactful measures. We prioritize supporting individuals such as orphans, elderly homeless individuals, residents of slums, and beggars, recognizing their immense potential to thrive if given the necessary opportunities. By standing alongside them and offering our support, we strive to nurture their growth and foster a more inclusive and compassionate society for all.
              </p>
            </div>
            <div className="about-image" id='about-image-id'>
              <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-indicators">
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src={require('./img/HOMELESS 3.jpg')} class="d-block w-100" alt="..." />
                  </div>
                  <div class="carousel-item">
                    <img src={require('./img/HOMELESS 2.jpg')} class="d-block w-100" alt="..." />
                  </div>
                  <div class="carousel-item">
                    <img src={require('./img/HOMELESS 1.jpg')} class="d-block w-100" alt="..." />
                  </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </section>
        </div>


        <div className='heading'>
          <h1>
            HEALTH
          </h1>
        </div>
        <div className="about-container " id='about-container-id'>
          <section className="about" id="about-id">
            <div className="about-content">
              <p id='text-align-id'>
                Health stands as a cornerstone of our NGO's mission, with our Hand for Help team steadfastly dedicated to safeguarding the well-being of all. We employ various strategies to promote health awareness and access to medical care. This includes organizing comprehensive health awareness campaigns, conducting free medical check-up camps, and offering treatment at no cost whenever feasible. Additionally, we extend our support to financially distressed individuals who are unable to afford proper healthcare, ensuring they receive the assistance they urgently require. Through these multifaceted efforts, we strive to ensure that everyone has the opportunity to lead a healthy and fulfilling life.
              </p>
            </div>
            <div className="about-image" id='about-image-id'>
              <div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item active" data-bs-interval="4000">
                    <img src={require('./img/HEALTH 1.jpg')} class="d-block w-100" alt="..." />
                  </div>
                  <div class="carousel-item" data-bs-interval="4000">
                    <img src={require('./img/HEALTH 2.jpg')} class="d-block w-100" alt="..." />
                  </div>
                  <div class="carousel-item" data-bs-interval="4000">
                    <img src={require('./img/HEALTH 3.jpg')} class="d-block w-100" alt="..." />
                  </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </section>
          <OurProject />
        </div>
      </div>
      <Footer />
    </>
  )
}

export default About
